body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #f3f3f3;
}

.container {
  background-color: white;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}

.centered h1 {
  font-size: 4vw;
  font-family: 'Playfair Display';
}

.centered h3 {
  font-size: 3vw;
  font-family: 'Playfair Display';
}

.banner-hidden {
  visibility: hidden;
  height:0;
}

h1, h2, h3, h4, h5 {
  color: #27592D;
}

ul.nav li a {
  color: #27592D;
  font-size: 1.2rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

ul.nav li a:hover {
  border-top: 1px solid #27592D;
  border-bottom: 1px solid #27592D;
}

ul.nav li a.selected {
  border-top: 1px solid #27592D;
  border-bottom: 1px solid #27592D;
}

.full-width {
  padding-right:0;
  padding-left:0;
}

ul.react-countdown li p {
  color: #27592D;
  font-family: 'Playfair Display';
}

.react-countdown li {
    width: 50px;
}

.react-countdown li:after {
    color: #27592D;
}

.App {
  text-align: center;
  background-color: #f8f9fa!important;
}

.App-header {
  min-height: 100px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #2e6da4;
  display: -webkit-flex;
  display: flex;
}

.App-link {
  color: #61dafb;
}

.vertical-timeline-element-title {
  color: #2e6da4;
}

.vertical-timeline-element-subtitle {
  color: #2e6da4;
}

.vertical-timeline-element-date {
  color: #2e6da4!important;
  text-align:center!important;
  font-size: 18px!important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.venue-name {
  color: #27592D;
  margin-bottom:0;
  font-size: 1.2em;
}

.sport {
  color: red;
}

