/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
}

.centered h1 {
  font-size: 4vw;
  font-family: 'Playfair Display';
}

.centered h3 {
  font-size: 3vw;
  font-family: 'Playfair Display';
}

.banner-hidden {
  visibility: hidden;
  height:0;
}

h1, h2, h3, h4, h5 {
  color: #27592D;
}

ul.nav li a {
  color: #27592D;
  font-size: 1.2rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

ul.nav li a:hover {
  border-top: 1px solid #27592D;
  border-bottom: 1px solid #27592D;
}

ul.nav li a.selected {
  border-top: 1px solid #27592D;
  border-bottom: 1px solid #27592D;
}

.full-width {
  padding-right:0;
  padding-left:0;
}

ul.react-countdown li p {
  color: #27592D;
  font-family: 'Playfair Display';
}

.react-countdown li {
    width: 50px;
}

.react-countdown li:after {
    color: #27592D;
}
